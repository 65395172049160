


































import Vue from "vue";
import create from "./components/create.vue";
import { getList } from "@/api/advert-space";
import moment from "moment";

export default Vue.extend({
  name: "AdvertSpaceIndex",
  filters: {
    timeFilter(value: string) {
      return moment(value).format("YYYY-MM-DD");
    }
  },
  components: {
    create
  },
  data() {
    return {
      form: {},
      typeList: [],
      list: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    indexMethod(index: number) {
      return index + 1;
    },
    async getList() {
      const { data } = await getList();
      if (data.state === 1) {
        this.list = data.content;
      } else {
        this.$message.error(data.message);
      }
    }
  }
});
